/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default () => (
  <Layout
    sx={{
      pt: 3,
      textAlign: 'center',
      maxWidth: 768,
      mx: 'auto'
    }}
  >
    <header>
      <div
        sx={{
          maxWidth: 768,
          mx: 'auto',
          px: 3,
          fontSize: 1,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Link
          to="/blog"
          sx={{
            ml: 'auto',
            py: 2,
            textDecoration: 'none',
            color: 'text',
            display: 'none'
          }}
        >
          Resume
        </Link>
      </div>
    </header>

    <div>
      <Link to="/" title="Home">
        <img
          sx={{
            marginTop: 4,
            marginBottom: 2,
            width: 200,
            borderRadius: 75
          }}
          alt="Bob and Toby"
          src="https://user-images.githubusercontent.com/1424573/70363998-e90e6200-1847-11ea-9a02-fc5e960518e2.jpg"
        />
      </Link>
    </div>

    <Styled.h1>
      Hi, I'm Robert Otander. <br /> I go by Bob.
    </Styled.h1>
  </Layout>
)
